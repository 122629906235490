<template>

<div @click="showModal">
  <v-btn v-show="!success" text class="font-weight-black text-center">{{status}}/{{total}}</v-btn>
  <v-btn v-show="success" text class="font-weight-black text-center"  >Superado</v-btn>
  <v-progress-linear v-show="!finished && !success" :value="getPercentage()" height="15" color="warning">Básico</v-progress-linear>
  <v-progress-linear v-show="!finished && success" value="100" height="15" color="success">Básico</v-progress-linear>
</div>


</template>

<script>

export default {
  name: 'progress-basic',
  props: ['status','total','success','finished'],
  methods: {
    getPercentage: function(){
      let percentage = 100 * (this.status - 1) / this.total
      return parseInt(percentage)
    },
    showModal: function(){
      let text = 'Vas por el verso '+this.status+'. El poema tiene '+this.total+' versos. En el modo básico realizarás el ejercicio completo de un sólo verso, cuando lo completes pasarás al verso siguiente. De esta manera irás aprendiendo los versos del poema uno a uno. No te preocupes si no aciertas el verso a la primera, las palabras acertadas se guardarán para que lo sigas intentando.'
      this.$root.$emit('showModal', { text , title : 'El modo básico' } )
    }
  }
}
</script>
