<template>
  
  <v-row justify="center">
    <v-dialog v-model="on" width="500px" persistent light :retain-focus="false">
      <v-card>
        <v-card-title>
          <span class="subtitle-1 font-weight-black">{{title}}</span>
        </v-card-title>
        <v-card-text class="body-1 font-weight-light text-justify" >
          {{text}}
        </v-card-text>
        <v-card-text class="body-1 font-weight-light text-justify" v-if="showThreePhases">
          <ol>
            <li><strong>Escuchar:</strong> primero debes escuchar un poema o verso en inglés a la vez que lo lees, de esta manera irás mejorando tu oído, relacionando lo que escuchas con lo que lees.</li>
            <li><strong>Traducir:</strong> después mejorarás tu vocabulario haciendo un pequeño ejercicio de traducción de lo que has oído. </li>
           <li><strong>Escribir:</strong> por último volverás a escuchar lo anterior, sin subtítulos y tendrás que escribir lo que oyes, para demostrar que eres capaz de reconocerlo.</li>
          </ol>
        </v-card-text>
        <v-card-actions>
           <v-btn color="green darken-1" block @click="next" :loading="loading">{{textButton}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>

import checkToken from '../../plugins/checkToken'

export default {
  name: 'tutorial',
  data() {
    return {
      on: false,
      title: '',
      text: '',
      count: 1,
      showThreePhases: false,
      textButton: 'Siguiente',
      loading: false
    }
  },
  mounted: function(){
    let that = this
    this.$root.$on('showTutorial',function(data){
      that.next()
      that.on = true
    })
  },
  methods: {
    next: function(){
      this.showThreePhases = false
      switch (this.count) {
        case 1:
          this.messageOne()
        break
        default:
          this.messageTwo()
      }
      this.count++
    },
    messageOne: function(){
      this.title = 'Bienvenido a poemsandlearn'
      this.text = 'Vamos a empezar con el modo básico de un poema de '+this.$store.state.tutorialStepAuthor
      this.textButton = 'Adelante'
    },
    messageTwo: function(){
      if (this.loading){
        return;
      }
      if(!checkToken(this.$store,this.$router)){
        return
      }
      this.loading = true
      let headers = {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }
      //headers['API-Version'] = ''+2
      this.$http.post(this.$store.state.urlApi+'purchase', {
          stepId: this.$store.state.tutorialStep
        },
        { headers}).then(response => {
        this.$store.state.coins = response.body.coins
        this.loading = false
        this.on = false
        this.$store.state.startRain = true
        this.$root.$emit('reloadHome')
      }, response => {
        this.$root.$emit('changeSnackbar', { show : true , text : 'Lo sentimos, no se pudo realizar la acción, inténtelo más tarde', timeout: 4000, type: 'error' } )
        this.loading = false
      })

    },
  },
  beforeDestroy: function(){
    this.$root.$off('showTutorial')
  }
}
</script>

<style>

</style>
