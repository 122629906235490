<template>
  
<v-alert
  :icon="false"
  :type="type"
>
  <v-row align="center">
    <v-col class="font-weight-black">{{message}}</v-col>
    <v-col class="shrink">
      <v-btn :loading="loading" :disabled="type !== 'success'" @click="initVsbot">Resolver reto</v-btn>
    </v-col>
  </v-row>

</v-alert>

</template>

<script>

import checkToken from '../../plugins/checkToken'

export default {
  name: 'VsbotButton',
  data() {
    return {
      type: 'info',
      message: '',
      loading: false
    }
  },
  methods: {
    render: function(nextVsbot,count){
      let nextVsbotMilliseconds = Date.parse(nextVsbot+'.000Z')
      let now = Date.now()
      let block = true
      if (! nextVsbot || now >= nextVsbotMilliseconds){
        this.type = 'success'
        this.message = '¿Te atreves con un reto?'
        block = false
      } else {
        let minutes = Math.round((nextVsbotMilliseconds - now) / 1000 / 60)
        if (minutes > 1){
          minutes = minutes+' minutos'
        } else {
          minutes = 'un minuto'
        }
        this.type = 'info'
        this.message = 'No puedes resolver el reto hasta dentro de '+minutes
      }
      this.$root.$emit('countVsbot', { block , count } )
    },
    initVsbot : function(){
      if(!checkToken(this.$store,this.$router)){
        return
      }
      if (this.$store.state.lockedVsBot){
        this.$root.$emit('showModal', { text : 'Completa el modo avanzado de un poema para desbloquear el reto.' , title : 'Reto bloqueado' } )
        return
      }
      this.loading = true
      this.$http.post(this.$store.state.urlApi+'vsbot/attempt', { 
        }, { headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }}).then(response => {
        this.loading = false
        this.$store.state.attemptId = response.body.id
        this.$store.state.step = {
            id: response.body.stepId,
            successWords: response.body.successWords,
            init: response.body.init,
            finish: response.body.finish,
            countWordsVerse: response.body.countWordsVerse,
            status: response.body.status
        }
        this.$store.state.fromStart = true
        this.$store.state.step.redirect = 'play-vsbot'
        if (response.body.successWords > 1){
          this.$store.state.step.message = 'Acierta al menos '+response.body.successWords+' palabras del siguiente verso para superar el reto'
        } else {
          this.$store.state.step.message = 'Acierta una palabra o más del siguiente verso para superar el reto'
        }
        this.$store.state.currentRoute = '/instructions'
        this.$router.push(this.$store.state.currentRoute)
      }, response => {
        this.loading = false
        this.$root.$emit('changeSnackbar', { show : true , text : 'No ha sido posible empezar el reto, inténtelo en unos minutos', timeout: 4000, type: 'error' } )
      })
    }
  }
};
</script>
