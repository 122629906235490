<template>

  <v-card class="elevation-2 mt-3 mr-1 ml-1 font-weight-thin" :id="'card-purchase-'+this.stepId" :color="getCardColor()" onresize="checkHeight()" >
    <v-layout align-center justify-center primary-title text-xs-center>
      <v-flex xs10 text-xs-left style="height:70px;">
        <h3 class="headline ml-2 font-weight-thin">{{author}}</h3>
      </v-flex>
      <v-flex xs2 text-xs-right style="height:70px;">
        <v-icon class="mr-1" v-if="finished">mdi-school</v-icon>
      </v-flex>
    </v-layout>
    <v-flex offset-xs2 xs8 sm8>
      <v-img
        v-if="getTimes() === 0"
        style="filter: grayscale(90%);"
        :src="$store.state.urlApi+'step/'+stepId+'/image?auth='+$store.state.accessToken"
        contain
      ></v-img>
      <v-img
        v-if="getTimes() >= 1"
        style="filter: sepia(80%);"
        :src="$store.state.urlApi+'step/'+stepId+'/image?auth='+$store.state.accessToken"
        v-on:load="imageLoad"
        contain
      ></v-img>
    </v-flex>
    <v-layout align-center justify-center ma-2 text-xs-center style="height:40px">
      {{title}}
    </v-layout>
    <v-layout align-center justify-center ma-2 text-xs-center class="font-weight-black" style="height:60px" v-if="finished">
      Último poema completado
    </v-layout>
    <v-layout pa-2 v-if="!finished">
      <v-flex xs4 text-left class="font-weight-black">
        <v-progress-circular
          :size="45"
          :width="6"
          :value="100"
          color="blue lighten-5"
          @click="infoPoints"
        >
          {{words}}
        </v-progress-circular>
      </v-flex>
      <v-flex xs4 text-center class="font-weight-black">
        <v-progress-circular
          :size="45"
          :width="6"
          :value="100"
          color="blue lighten-5"
          @click="infoStreakDays"
        >
          {{priceStreakDays}}
        </v-progress-circular>
      </v-flex>
      <v-flex xs4 text-right class="font-weight-black">
        <v-badge
          color="blue lighten-5"
          left
        >
          <template v-slot:badge>
            <span class="black--text">{{priceVsbot}}</span>
          </template>
          <v-icon :size="45" color="blue lighten-5" @click="infoVsbot">mdi-school</v-icon>
        </v-badge>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout pa-2>
      <v-flex xs4 text-left >
        <span v-if="!finished" @click="infoTime"><v-icon  class="mr-1">mdi-timer</v-icon>{{duration}}s</span>
      </v-flex>
      <v-flex xs4 text-center class="font-weight-black">
        <span v-if="getTimes() >= 1">Nivel {{getTimes()}}</span>
      </v-flex>
      <v-flex xs4 text-right >
        <span v-if="!finished" @click="infoWords"><v-icon class="mr-1">mdi-translate</v-icon>{{words}}</span>
      </v-flex>
    </v-layout>
    <v-card-actions>
      <v-layout align-center justify-center primary-title text-xs-center pb-2>
        <v-btn :disabled="getDisabled()" :loading="loading" @click="purchase" color="purple">{{textButton}}</v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>

</template>

<script>

export default {
  name: 'card-purchase',
  props: ['stepId','title','author','words','duration','finishedCounter','finished','priceVsbot','priceStreakDays'],
  data() {
    return {
      image: '',
      loading: false,
      textButton: 'Desbloquear'
    }
  },
  mounted: function(){
    if (this.getTimes() >= 1){
      this.textButton = 'Subir de nivel'
    }
  },
  methods: {
    imageLoad: function(){
      let that = this
      setTimeout(function(){
        let cartPurchase = document.getElementById('card-purchase-'+that.stepId)
        let stepsDoneCard = document.getElementById('stepsDoneCard')
        if (!cartPurchase || !stepsDoneCard){
          return
        }
        let newHeight = cartPurchase.offsetHeight
        if (!window.stepsDoneCardHeight || window.stepsDoneCardHeight > newHeight){
          stepsDoneCard.style.height = newHeight+'px'
          window.stepsDoneCardHeight = newHeight
        }
      },100)
    },
    getDisabled: function(){
      return this.finished
    },
    getCardColor: function(){
      if (this.finished) {
        return 'light'
      }
      switch (this.finishedCounter) {
        case null:
          return 'indigo';
        case 1:
          return 'teal';
        case 2:
          return 'green darken-1';
        case 3:
          return 'grey darken-2';
        case 4:
          return 'blue darken-2';
        case 5:
          return 'cyan darken-2';
        case 6:
          return 'blue-grey';
        case 7:
          return 'brown';
        default:
          return 'black';
      }
    },
    getTimes(){
      return this.finishedCounter === null ? 0 : this.finishedCounter
    },
    infoPoints(){
      let text = 'Necesitas ' + this.words + ' puntos para desbloquear este poema.'
      this.$root.$emit('showModal', { text , title : 'Puntos necesarios' } )
    },
    infoTime(){
      let text = 'Este poema dura '+this.duration+' segundos.'
      this.$root.$emit('showModal', { text , title : 'Duración' } )
    },
    infoWords(){
      let text = 'Este poema tiene '+this.words+' palabras.'
      this.$root.$emit('showModal', { text , title : 'Palabras' } )
    },
    infoStreakDays(){
      let text = ''
      if (this.priceStreakDays == 1){
        text = 'Necesitas un punto de racha para desbloquear este poema.'
      } else {
        text = 'Necesitas '+this.priceStreakDays+' puntos de racha para desbloquear este poema.'
      }
      this.$root.$emit('showModal', { text , title : 'Puntos de racha necesarios' } )
    },
    infoVsbot(){
      let text = ''
      if (this.priceVsbot == 1){
        text = 'Necesitas un punto de reto para desbloquear este poema.'
      } else {
        text = 'Necesitas '+this.priceVsbot+' puntos de reto para desbloquear este poema.'
      }
      this.$root.$emit('showModal', { text , title : 'Puntos de reto necesarios' } )
    },
    purchase: function(){
      if (this.$store.state.lockedVsBot){
        this.$root.$emit('showModal', { text : 'Completa el modo avanzado de un poema para desbloquear "'+this.title+'" de '+this.author+'.' , title : 'Poema bloqueado' } )
        return
      }
      let times = this.getTimes()
      this.$root.$emit('showPurchaseModal', { 
        times: times , 
        stepId: this.stepId , 
        title: this.title , 
        points : this.words, 
        priceStreakDays: this.priceStreakDays , 
        priceVsbot: this.priceVsbot 
      } )
    }
  }
}
</script>

<style>

</style>
