const checkToken = function(store,router)
{
	if (store.state.accessToken && new Date().getTime() / 1000 < store.state.expireAt) {
		return true
	} 
    store.state.currentRoute = '/refresh';
    router.push('refresh');
	return false
}

export default checkToken
