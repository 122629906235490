<template>
<div>
  <bar title="true"/>
  <simple-modal/>
</div>
</template>

<script>

import bar from '../components/appComponents/Bar.vue'
import simpleModal from '../components/modals/SimpleModal.vue'

export default {
  name: 'refresh',
  components: {
    bar,
    'simple-modal' : simpleModal
  },
  mounted: function(){

    let that = this
    this.$root.$on('closedModal',function(data){
      that.$store.state.currentRoute = '/home'
      that.$router.push(this.$store.state.currentRoute)
    })

    this.$store.state.loading = true;
    this.$store.commit('recalculateCaptcha')
    this.$http.post(this.$store.state.urlApi+'auth/refresh', { 
          refresh_token: this.$store.state.refreshToken
      }, { headers: {
          Accept: 'application/json',
          'Captcha-Authorization': this.$store.state.captchaTokenResult
    }}).then(function(response){
      this.$store.state.loading = false
      this.$store.state.accessToken = response.body.access_token
      this.$store.state.refreshToken = response.body.refresh_token
      this.$store.state.anonymous = response.body.anonymous
      this.$store.state.expireAt = new Date().getTime() / 1000 + response.body.expires_in
      this.$store.state.refreshExpireAt = new Date().getTime() / 1000 + response.body.refresh_expires_in
      this.$store.state.anotherTokenRemoving = response.body.another_token_removing
      this.$store.state.refreshHome = true
      if (this.$store.state.anonymous){
        this.$root.$emit('showModal', { text:'No estás registrado, si pierdes la sesión perderás todo tu progreso. Esto ocurrirá al menos si no utilizas la aplicación durante 2 semanas. Puedes crear una cuenta desde la sección de Ajustes.' , title : 'Puedes perder tu progreso' } )
      } else {
        this.$store.state.currentRoute = '/home'
        this.$router.push(this.$store.state.currentRoute)
      }
    }, function(response){
      if(response.status === 401){
        this.$store.state.currentRoute = '/'
        window[this.$store.state.storage].clear()
      } else {
        this.$store.state.currentRoute = '/try-again'
      }
      this.$store.state.loading = false
      this.$router.push(this.$store.state.currentRoute)
    });
  },
  beforeDestroy: function(){
    this.$root.$off('closedModal')
  }
}
</script>

<style>

</style>
