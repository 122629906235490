<template>

<div @click="showModal">
  <v-btn v-if="!finished" text class="font-weight-black text-center" >{{getPercentage()}}%</v-btn>
  <v-progress-linear v-if="!finished" :value="getPercentage()" height="15" color="warning">Avanzado</v-progress-linear>
  <v-progress-linear v-if="finished" value="100" height="15" color="success"></v-progress-linear>
</div>


</template>

<script>

export default {
  name: 'progress-advanced',
  props: ['percentage','finished'],
  methods: {
    getPercentage: function(){
      let percentage = this.percentage * 100 / 60
      return parseInt(percentage)
    },
    showModal: function(){
      let text = 'Llevas un '+this.getPercentage()+'% de progreso. En el modo avanzado realizarás el ejercicio sobre el poema entero. Es prácticamente imposible superar el modo avanzado en un sólo intento, concéntrate en acertar el máximo de palabras cada vez, todas las palabras acertadas quedarán guardadas para otros intentos que realices después o incluso otro día.'
      this.$root.$emit('showModal', { text , title : 'El modo avanzado' } )
    }
  }
}
</script>
