<template>
  
  <v-row justify="center">
    <v-dialog v-model="on" width="500px" persistent :retain-focus="false">
      <v-card>
        <v-card-title>
          <span class="subtitle-1 font-weight-black text-center">{{title}}</span>
        </v-card-title>
        <v-card-text class="body-1 font-weight-light text-center" >
          Para {{verb}} el poema necesitas:
        </v-card-text>
        <v-card-text class="body-1 font-weight-light text-justify" >
          <v-layout>
            <v-flex xs4 text-left class="font-weight-black">
              <v-progress-circular
                :size="45"
                :width="6"
                :value="100"
                color="blue lighten-5"
              >
                {{points}}
              </v-progress-circular>
            </v-flex>
            <v-flex xs4 text-center class="font-weight-black">
              <v-progress-circular
                :size="45"
                :width="6"
                :value="100"
                color="blue lighten-5"
              >
                {{priceStreakDays}}
              </v-progress-circular>
            </v-flex>
            <v-flex xs4 text-right class="font-weight-black">
              <v-badge
                color="blue lighten-5"
                left
              >
                <template v-slot:badge>
                  <span class="black--text">{{priceVsbot}}</span>
                </template>
                <v-icon :size="45" color="blue lighten-5">mdi-school</v-icon>
              </v-badge>
            </v-flex>
          </v-layout>
          <v-layout class="mt-1">
            <v-flex xs4 text-left class="font-weight-black">
              Puntos
            </v-flex>
            <v-flex xs4 text-center class="font-weight-black">
              Puntos de racha
            </v-flex>
            <v-flex xs4 text-right class="font-weight-black">
              Puntos de reto
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text class="body-1 font-weight-light text-center" v-if="disabled">
          {{disabledMessage}}
        </v-card-text>
        <v-card-actions>
            <v-flex xs6 class="pa-2">
              <v-btn color="warning" block @click="on = false" :disabled="loading">Cancelar</v-btn>
            </v-flex>
            <v-flex xs6 class="pa-2">
              <v-btn color="green darken-1" block @click="purchase" :disabled="disabled" :loading="loading">{{verb}}</v-btn>
            </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>

import checkToken from '../../plugins/checkToken'

export default {
  name: 'purchase-modal',
  data() {
    return {
      on: false,
      title: '',
      points: 0,
      priceStreakDays: 0,
      priceVsbot: 0,
      disabled: true,
      disabledMessage: '',
      loading: false,
      stepId: 0,
      verb: 'desbloquear'
    }
  },
  mounted: function(){
    let that = this
    this.$root.$on('showPurchaseModal',function(data){
      that.verb = data.times & data.times > 0 ? 'subir de nivel' : 'desbloquear'
      that.stepId = data.stepId
      that.title = data.title
      that.points = data.points
      that.priceStreakDays = data.priceStreakDays
      that.priceVsbot = data.priceVsbot
      let countPoints = that.$store.state.coins - that.points
      let countVsbot = that.$store.state.countVsbot - that.priceVsbot
      let countStreak = that.$store.state.countStreak - that.priceStreakDays
      that.disabled = true
      if (countPoints < 0){
          countPoints = countPoints * -1
          that.disabledMessage = 'Te faltan '+countPoints+' puntos'
      } else if (countStreak < 0){
          countStreak = countStreak * -1
          if (countStreak == 1){
            that.disabledMessage = 'Te falta un punto de racha'
          } else {
            that.disabledMessage = 'Te faltan '+countStreak+' puntos de racha'
          }
      } else if (countVsbot < 0){
          countVsbot = countVsbot * -1
          if (countVsbot == 1){
            that.disabledMessage = 'Te falta un punto de reto'
          } else {
            that.disabledMessage = 'Te faltan '+countVsbot+' puntos de reto'
          }
      } else {
        that.disabled = false
      }

      if (that.disabled) {
        that.disabledMessage += '. Consigue lo que necesitas para '+that.verb+' este poema.'
      }
      that.on = true
    })
  },
  methods: {
    purchase: function(){
      if (this.loading){
        return;
      }
      if(!checkToken(this.$store,this.$router)){
        return
      }
      this.loading = true
      let headers = {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }
      headers['API-Version'] = ''+2
      this.$http.post(this.$store.state.urlApi+'purchase', {
          stepId: this.stepId
        },
        { headers}).then(response => {
        this.$store.state.coins = response.body.coins;
        this.loading = false
        this.on = false
        this.$root.$emit('reloadHome')
      }, response => {
        this.$root.$emit('changeSnackbar', { show : true , text : 'Lo sentimos, no se pudo realizar la acción, inténtelo más tarde', timeout: 4000, type: 'error' } )
        this.loading = false
      })
    }
  },
  beforeDestroy: function(){
    this.$root.$off('showPurchaseModal')
  }
}
</script>

<style>

</style>
