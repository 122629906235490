<template>
<div>
<bar logout="true" progress="true" homeTabs="true"/>

<v-content>
  <message/>
<v-container grid-list-md>
  <v-layout row wrap>
      
      <v-flex md12 sm12 xs12>
        
        <vsbot-button v-show="!loading" ref="vsbotButton"/>

        <v-card
          class="pa-2"
          elevation="24"
          color="dark"
          outlined
          id="tabs-current"
          v-show="!loading"
        >

          <span class="font-weight-medium">Estudiando</span>

          <v-layout row wrap class="d-flex">
            
            <v-flex sm6 xs12 md4 v-for="step in steps" :key="step.id" v-if="!step.user_step_finished">
              <div class="mt-2">
                <cardHome ref="cardHome" :stepId="step.step_id" :finished="step.user_step_finished" :finishedCounter="step.user_step_times" :author="step.step_author" :title="step.step_title" :percentage="step.user_step_percentage" :words="step.user_step_words" :basicStatus="step.user_step_basic_status" :basicTotal="step.user_step_basic_total" :basicSuccess="step.user_step_basic_success" :duration="step.steps_duration" :advancedLocked="step.user_step_advanced_locked"></cardHome>
              </div>
            </v-flex>

            <v-alert
              v-if="steps.length === 0"
              dark
              outlined
              tile
              class="text-center mt-2"
              width="100%"
             >
              Selecciona el poema que más te guste más abajo y supéralo.
            </v-alert>

          </v-layout>

        </v-card>

        <v-card
          class="pa-2 mt-2"
          color="dark"
          outlined
          id="tabs-todo"
          v-show="!loading"
        >

          <span class="font-weight-medium">Bloqueados</span>

          <v-layout row wrap class="d-flex">
            
            <v-flex sm6 xs12 md4 v-for="step in stepsTodo" :key="step.id">
              <div  class="mt-2">
                <card-purchase :stepId="step.id" :finishedCounter="step.times" :duration="step.duration" :title="step.title" :author="step.author" :words="step.words" :priceVsbot="step.priceVsbot" :priceStreakDays="step.priceStreakDays"></card-purchase>
              </div>
            </v-flex>

            <v-alert
              v-if="stepsTodo.length === 0"
              dark
              outlined
              tile
              class="text-center mt-2"
              width="100%"
             >
              ¡Enhorabuena! Has completado todos los poemas, puedes subir de nivel los poemas superados hasta que se añadan nuevos poemas.
            </v-alert>

          </v-layout>

        </v-card>

        <v-card
          class="pa-2 mt-2 mb-2"
          color="dark"
          outlined
          id="tabs-done"
          v-show="!loading"
        >

          <span class="font-weight-medium">Superados</span>

          <v-layout row wrap class="d-flex">
            
            <v-flex sm6 xs12 md4 v-for="step in stepsDone" :key="step.id">
              <div  class="mt-2">
                <card-purchase :stepId="step.id" :finishedCounter="step.times" :duration="step.duration" :title="step.title" :author="step.author" :words="step.words" :finished="step.finished" :priceVsbot="step.priceVsbot" :priceStreakDays="step.priceStreakDays"></card-purchase>
              </div>
            </v-flex>

            <v-flex sm6 xs12 md4 v-if="$store.state.stepsDonePage > 0">
              <div  class="mt-2">
                <v-card color="white" class="d-flex flex-nowrap" id="stepsDoneCard">
                  <v-layout @click="stepsDoneNewPage" align-center justify-center primary-title text-xs-center v-ripple="{ class: 'black--text' }">
                    <v-icon size="200" color="black darken-2">mdi-plus</v-icon>
                  </v-layout>
                </v-card>
              </div>
            </v-flex>

            <v-alert
              v-if="stepsDone.length === 0"
              dark
              outlined
              tile
              class="text-center mt-2"
              width="100%"
             >
              Debes completar el modo avanzado de un poema para añadirlo a esta sección.
            </v-alert>

          </v-layout>

        </v-card>

      </v-flex>
  
  </v-layout>
</v-container>

<simple-modal/>
<purchase-modal/>
<tutorial/>

</v-content>

<pl-footer/>

</div>
</template>

<script>

import cardHome from '../components/launchers/CardHome.vue'
import bar from '../components/appComponents/Bar.vue'
import message from '../components/appComponents/Message.vue'
import footer from '../components/appComponents/Footer.vue'
import checkToken from '../plugins/checkToken'
import vsbotButton from '../components/launchers/VsbotButton'
import cardPurchase from '../components/launchers/Card-Purchase.vue'
import simpleModal from '../components/modals/SimpleModal.vue'
import purchaseModal from '../components/modals/PurchaseModal.vue'
import tutorial from '../components/modals/Tutorial.vue'

export default {
  name: 'Home',
  data() {
    return {
      loading: true,
      stepsTodo: [],
      stepsDone: [],
      steps: []
    }
  },
  components: {
    cardHome,
    cardPurchase,
    'pl-footer':footer,
    message,
    bar,
    'vsbot-button':vsbotButton,
    'simple-modal' : simpleModal,
    'purchase-modal' : purchaseModal,
    tutorial
  },
  mounted(){
    let that = this
    this.$root.$on('reloadHome',function(){
      that.steps = []
      that.stepsRight = []
      that.stepsTodo = []
      that.stepsDone = []
      window.scroll(0, 0)
      that.loading = true
      that.$store.state.loading = true
      that.init()
    })
    this.$store.state.loading = true
    if ( !this.$store.state.refreshHome ){
      this.loading = false;
      this.$store.state.loading = false;
      this.fillCards();
      this.$refs.vsbotButton.render(this.$store.state.nextVsbot,this.$store.state.countVsbot)
      return;
    }
    this.init()
  },
  beforeDestroy: function(){
    this.$root.$off('reloadHome')
  },
  methods: {
    init(){
      this.$store.state.lockedVsBot = true
      this.$store.state.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (this.$store.state.anotherTokenRemoving) {
        this.$store.state.anotherTokenRemoving = false
        this.$root.$emit('showModal', { text: 'Se ha cerrado una sesión abierta en otro dispositivo. En la última hora ha accedido a la plataforma desde otro dispositivo, o navegador, la sesión abierta en ese dispositivo ha sido cerrada.' , title : 'Sesión cerrada' } )
      }
      this.$http.get(this.$store.state.urlApi+'status?timezone='+this.$store.state.timezone,
        { headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }}).then(response => {
        this.$store.state.steps = []
        let stepFinished = false
        for (let i in response.body.steps){
          if (response.body.steps[i].user_step_finished) {
            stepFinished = {
              id: response.body.steps[i].step_id,
              times: response.body.steps[i].user_step_times,
              duration: response.body.steps[i].steps_duration,
              title: response.body.steps[i].step_title,
              words: response.body.steps[i].user_step_words,
              author: response.body.steps[i].step_author,
              finished: true
            }
          } else {
            this.$store.state.steps.push(response.body.steps[i])
          }
        }
        if(response.body.purchaseStepsTodo.length  > 9){
          response.body.purchaseStepsTodo.pop()
        }
        this.$store.state.stepsTodo = response.body.purchaseStepsTodo
        if ( response.body.purchaseStepsDone.length > 9 ) {
          this.$store.state.stepsDonePoem = response.body.purchaseStepsDone.pop()
          this.$store.state.stepsDonePage = 2
        }
        this.$store.state.stepsDone = response.body.purchaseStepsDone
        if (stepFinished){
          this.$store.state.lockedVsBot = false
          this.$store.state.stepsDone.unshift(stepFinished)
          this.$store.state.stepsDoneFinished = stepFinished
        }
        this.$store.state.coins = response.body.coins
        this.$store.state.userName = response.body.name
        this.$store.state.increaseWordsObjetive = response.body.increaseWordsObjetive
        this.$store.state.increaseWordsToday = response.body.increaseWordsToday ? response.body.increaseWordsToday : 0
        this.$store.state.countVsbot = response.body.countVsbot
        this.$store.state.nextVsbot = response.body.nextVsbot
        this.$store.state.countStreak = response.body.countStreak
        this.$store.state.objetiveStreak = response.body.objetiveStreak
        this.$store.state.instructionsBasicStudy = response.body.instructionsBasicStudy
        this.$store.state.instructionsBasicLearnWords = response.body.instructionsBasicLearnWords
        this.$store.state.instructionsBasicPlay = response.body.instructionsBasicPlay
        this.$store.state.instructionsAdvancedStudy = response.body.instructionsAdvancedStudy
        this.$store.state.instructionsAdvancedLearnWords = response.body.instructionsAdvancedLearnWords
        this.$store.state.instructionsAdvancedPlay = response.body.instructionsAdvancedPlay
        this.$store.state.tutorialStep = response.body.tutorialStep
        this.$store.state.tutorialStepTitle = response.body.tutorialStepTitle
        this.$store.state.tutorialStepAuthor = response.body.tutorialStepAuthor
        this.$store.commit('refreshMessageToday')
        this.$root.$emit('updateProgressToday')
        
        this.loading = false;
        this.$store.state.loading = false;
        this.$store.state.refreshHome = false;

        if (this.$store.state.lockedVsBot && this.$store.state.steps.length === 0) {
          this.$root.$emit('showTutorial')
        }

        this.fillCards();
        this.$refs.vsbotButton.render(this.$store.state.nextVsbot,this.$store.state.countVsbot)

      }, response => {
        this.loading = false
        this.$store.state.loading = false
        this.$store.state.accessToken = ''
        this.$store.state.refreshHome = true
        if(response.status === 401 && !checkToken(this.$store,this.$router)){
          return
        }
        this.$store.state.currentRoute = '/try-again'
        this.$router.push(this.$store.state.currentRoute)
      });
    },
    stepsDoneNewPage(){

      let headers = {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken,
            Page: ''+this.$store.state.stepsDonePage,
            ManyElements: ''+true,
            Pass: ''+true
      }

      this.$store.state.loading = true
      this.$http.get(this.$store.state.urlApi+'purchase/steps', { headers} ).then(response => {
        if ( response.body.length > 9 ) {
          if (this.$store.state.stepsDonePoem){
            this.$store.state.stepsDone = [this.$store.state.stepsDonePoem]
          }
          this.$store.state.stepsDonePoem = response.body.pop()
          this.$store.state.stepsDone = response.body
          if (this.$store.state.stepsDonePage === 1){
            this.$store.state.stepsDone.unshift(this.$store.state.stepsDoneFinished)
          }
          this.$store.state.stepsDonePage++
        } else {
          this.$store.state.stepsDone = response.body
          this.$store.state.stepsDone.unshift(this.$store.state.stepsDonePoem)
          this.$store.state.stepsDonePoem = false
          this.$store.state.stepsDonePage = 1
        }
        this.fillCards()
        let that = this
        setTimeout(function(){
          that.$vuetify.goTo('#tabs-done', {
            duration: 500,
            offset: 20,
            easing: 'linear'
          })
        },100)
        this.$store.state.loading = false

      }, response => {
        this.$root.$emit('changeSnackbar', { show : true , text : 'Lo sentimos, no se pudo realizar la acción, inténtelo más tarde', timeout: 4000, type: 'error' } )
        this.$store.state.loading = false
      })
    },
    fillCards(){
      this.stepsDone = this.$store.state.stepsDone
      this.stepsTodo = this.$store.state.stepsTodo
      this.steps = this.$store.state.steps
    },
    purchase: function(){
      if(!checkToken(this.$store,this.$router)){
        return
      }
      this.$store.state.currentRoute = '/purchase';
      this.$router.push('purchase');
    },
    settings: function(){
      if(!checkToken(this.$store,this.$router)){
        return
      }
      this.$store.state.currentRoute = '/settings';
      this.$router.push('settings');
    }
  }
}
</script>

<style>

</style>
