<template>
<div>

  <v-card :color="getCardColor()" class="white--text" >
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card-title primary-title>
          <v-flex xs9 text-xs-left>
            <p class="font-weight-thin subtitle-1" style="height:20px;">{{author}}</p>
          </v-flex>
          <v-flex xs3 text-right class="font-weight-black subtitle-1">
            Nivel {{getTimes()}}
          </v-flex>
        </v-card-title>
      </v-flex>
      <v-flex xs12 sm12>
        <v-img
          style="filter: drop-shadow(0px 0px 10px yellow);"
          :src="$store.state.urlApi+'step/'+stepId+'/image?auth='+$store.state.accessToken"
          contain
        ></v-img>
      </v-flex>
      <v-flex xs12 sm12>
        <v-card-title primary-title>
          <v-flex xs8 text-xs-left>
            <p class="font-weight-thin" style="height:30px;">{{title}}</p>
          </v-flex>
          <v-flex xs4 text-right>
            <v-btn text color="gray" v-if="!finished" @click.stop="dialog = true">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-flex>
        </v-card-title>
      </v-flex>
      <v-flex xs6 sm6 class="pl-2" text-center>
        <progress-basic :status="basicStatus" :total="basicTotal" :success="basicSuccess" :finished="finished"/>
      </v-flex>
      <v-flex xs6 sm6 class="pr-2" text-center>
        <progress-advanced :percentage="percentage" :finished="finished"/>
      </v-flex>
    </v-layout>
    <v-card-actions>
      <v-layout row wrap>
        <v-flex xs6 sm6>
          <v-btn v-if="!finished" :disabled="basicSuccess" block color="success" @click="doItBasic()">Básico</v-btn>
        </v-flex>
        <v-flex xs6 sm6>
          <v-btn v-if="!finished" block color="warning" @click="doIt()">Avanzado</v-btn>
        </v-flex>
        <v-flex xs12 sm12>
          <v-btn text disabled v-show="finished" block>Hecho</v-btn>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>


    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="headline">Abandonar poema</v-card-title>
        <v-card-text>
          ¿Deseas dejar de estudiar este poema? Perderás todo tu progreso.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

            <v-btn color="warning" text @click="dialog = false">No</v-btn>
            <v-btn color="success" @click="deleteStep()">Sí</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</div>
</template>

<script>

import checkToken from '../../plugins/checkToken'
import progressBasic from '../markers/ProgressBasic.vue'
import progressAdvanced from '../markers/ProgressAdvanced.vue'

export default {
  name: 'CardHome',
  props: ['finishedCounter','author','stepId','finished','title','percentage','words','duration','basicStatus','basicSuccess','basicTotal','advancedLocked'],
  data () {
    return {
      dialog: false,
    }
  },
  components: {
    'progress-basic' : progressBasic,
    'progress-advanced' : progressAdvanced
  },
  mounted: function(){
    if (this.$store.state.startRain){
      this.$store.state.startRain = false
      this.doItBasic()
    }
  },
  methods: {
    deleteStep: function(){
      if(!checkToken(this.$store,this.$router)){
        return
      }
      this.dialog = false
      this.$store.state.loading = true
      this.$http.delete(this.$store.state.urlApi+'step/'+this.stepId, { headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }}).then(function() {
        this.$root.$emit('reloadHome')
      }, function() {
        this.$store.state.loading = false
        this.dialog = false;
        this.$root.$emit('changeSnackbar', { 
          show : true , 
          text : 'Ocurrió un error inesperado, inténtelo más tarde' , 
          timeout: 4000, 
          type : 'error' 
        } )
      });
    },
    getCardColor: function(){
      switch (this.finishedCounter) {
        case null:
          return 'indigo';
        case 1:
          return 'teal';
        case 2:
          return 'green darken-1';
        case 3:
          return 'grey darken-2';
        case 4:
          return 'blue darken-2';
        case 5:
          return 'cyan darken-2';
        case 6:
          return 'blue-grey';
        case 7:
          return 'brown';
        default:
          return 'black';
      }
    },
    getTimes(){
      return this.finishedCounter === null ? 0 : this.finishedCounter
    },
    purchase: function(){
      this.$store.state.currentRoute = '/purchase';
      this.$router.push('purchase');
    },
    doIt: function(){
      if(!checkToken(this.$store,this.$router)){
        return
      }
      let toLock = false
      if (!this.basicSuccess){
        if (this.basicStatus < this.advancedLocked){
          let text = ''
          let operand = this.advancedLocked
          if (operand > this.basicTotal){
            operand = this.basicTotal + 1
          }
          let count = operand - this.basicStatus
          if (count == 1) {
            text = 'Debes superar un verso en el nivel básico para desbloquear el modo avanzado.'
          } else {
            text = 'Debes superar '+count+' versos en el nivel básico para desbloquear el modo avanzado.'
          }
          this.$root.$emit('showModal', { text , title : 'Modo avanzado bloqueado' } )
          return
        }
        toLock = true
      }
      this.$store.state.step = {
          id: this.stepId
        , author: this.author
        , title: this.title
        , percentage: parseFloat(this.percentage)
        , words: this.words
        , duration: this.duration
        , toLock
      }
      this.$store.state.attemptId = false
      this.$store.state.fromStart = true
      this.$store.state.currentRoute = '/study'
      this.$router.push(this.$store.state.currentRoute)
    },
    doItBasic: function(){
      if(!checkToken(this.$store,this.$router)){
        return
      }
      this.$store.state.step = {
          id: this.stepId
        , author: this.author
        , title: this.title
        , status: this.basicStatus
        , total: this.basicTotal
        , duration: this.duration
        , captions: false
        , ids: []
      }
      this.$store.state.unknownWords = []
      this.$store.state.attemptId = false
      this.$store.state.fromStart = true
      this.$store.state.currentRoute = '/start-basic'
      this.$router.push(this.$store.state.currentRoute)
    }
  }
}
</script>

<style>

</style>
