<template>
  
  <v-row justify="center">
    <v-dialog v-model="on" width="500px" persistent light :retain-focus="false">
      <v-card>
        <v-card-title>
          <span class="subtitle-1 font-weight-black">{{title}}</span>
        </v-card-title>
        <v-card-text class="body-1 font-weight-light text-justify" >
          {{text}}
        </v-card-text>
        <v-card-actions>
           <v-btn color="green darken-1" block @click="accept">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>

export default {
  name: 'simple-modal',
  data() {
    return {
      on: false,
      title: '',
      text: ''
    }
  },
  mounted: function(){
    let that = this
    this.$root.$on('showModal',function(data){
      that.title = data.title
      that.text = data.text
      that.on = true
    })
  },
  methods: {
    accept: function() {
      this.on = false
      this.$root.$emit('closedModal')
    } 
  },
  beforeDestroy: function(){
    this.$root.$off('showModal')
  }
}
</script>

<style>

</style>
